import { renderRichText } from "gatsby-source-contentful/rich-text";
import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row, media } from "styled-bootstrap-grid"
import styled from "styled-components"

import {
  Card,
  Layout,
  LinkButton,
  Menu,
  Paragraph,
  SEO,
  SocialMedia,
} from "../components"
import ArrowDown from "../images/arrow-down.svg"
import ArrowRight from "../images/arrow-right.svg"
import { Colors } from "../utils/constants"

const JobOpeningPage = ({
  data,
  data: {
    contentfulJobOpening: {
      title,
      location: jobLocation,
      contract,
      salary,
      jobRole,
      description,
    },
  },
}) => {
  const socialMedias = data.socialMedias.nodes[0]
  const backgroundImage =
    data.jobOpportunity.nodes[0].background.localFile?.childImageSharp
  const jobOpportunity = data.jobOpportunity.nodes[0]
  const jobs = jobOpportunity.jobs.map(({ title, slug }) => ({
    label: title,
    href: "/careers/" + slug,
    icon: ArrowRight,
  }))

  return (
    <Layout>
      <SEO title="Job Details" />
      <StyledTopContainer backgroundImage={backgroundImage} fluid>
        <StyledDescription>
          <Col col="12" md="9">
            {title && <h1>{title}</h1>}
            {(jobLocation || contract || salary) && (
              <div>
                <span>{jobLocation}</span>
                <span>{contract}</span>
                <span>{salary}</span>
              </div>
            )}
            {jobRole && <h4>{jobRole}</h4>}
          </Col>
        </StyledDescription>
        <Row>
          <Col>
            <LinkButton
              theme="red"
              href={`/careers/apply?jobPosition=${encodeURI(title)}`}
            >
              Easy Apply
            </LinkButton>
          </Col>
        </Row>
        <StyledContent arrowDown={ArrowDown}>
          <Col col="12" md="9">
            {renderRichText(description)}
          </Col>
        </StyledContent>
        <StyledButtonCointainer>
          <Col col="12" md="4">
            <LinkButton
              theme="red"
              stretched
              href={`/careers/apply?jobPosition=${encodeURI(title)}`}
            >
              Easy Apply
            </LinkButton>
          </Col>
          <Col col="12" md="4">
            <LinkButton
              theme="inverted"
              stretched
              href="mailto:talents@xtech.guru"
            >
              E-mail your application
            </LinkButton>
          </Col>
        </StyledButtonCointainer>
      </StyledTopContainer>
      {/* similar positions section */}
      <StyledPositionContainer>
        <Row>
          <Col>
            <Paragraph
              title={jobOpportunity.title}
              content={jobOpportunity.content.content}
            />
          </Col>
        </Row>
        <StyledListContainer>
          <Col col="12" md="8">
            <Menu items={jobs} stretched isVertical theme="list" />
            <LinkButton
              theme="inverted"
              stretched
              href={jobOpportunity.link.url}
            >
              {jobOpportunity.link.content}
            </LinkButton>
          </Col>
          <Col col="12" md="4">
            <Card
              description={jobOpportunity.jobCard.content.content}
              title={jobOpportunity.jobCard.title}
              isVertical
            >
              <LinkButton
                theme="red"
                href={jobOpportunity.jobCard.link.url}
                stretched
              >
                {jobOpportunity.jobCard.link.content}
              </LinkButton>
            </Card>
          </Col>
        </StyledListContainer>
      </StyledPositionContainer>
      {/* social media section */}
      {socialMedias && (
        <SocialMedia
          socialMedias={{
            socialMedia: socialMedias.socialMedia,
          }}
        />
      )}
    </Layout>
  )
}

const StyledTopContainer = styled(Container)`
  padding: 50px 20px;
  background-repeat: no-repeat;
  ${media.md`
    padding-left:10%;
    background-image: url(${props => props.backgroundImage?.tabletImage?.src});
    background-position: top -20px right -100px;
  `}
  ${media.lg`
    padding-top:90px;
    padding-left:10%;
    background-image: url(${props => props.backgroundImage?.desktopImage?.src});
    background-position: top -50px right -100px;
  `}
`

const StyledDescription = styled(Row)`
  h1 {
    font-size: 25px;
    margin-bottom: 30px;
    color: ${Colors.RED};
  }
  span {
    color: ${Colors.GREY};
    font-weight: bold;
    font-size: 16px;
  }
  h4 {
    margin: 10px 0 30px;
    color: ${Colors.RED};
    font-size: 14px;
  }
  span:not(:last-of-type):after {
    content: " • ";
    color: ${Colors.GREY};
  }
`

const StyledContent = styled(Row)`
  margin: 47px 0 60px;
  p,
  li {
    font-size: 12px;
    color: ${Colors.GREY};
    line-height: 1.8;
  }
  h3 {
    font-size: 14px;
    color: ${Colors.RED};
  }
  ${media.md`
    p,
    li {
      font-size: 14px;
      color: ${Colors.GREY};
    }
    h3 {
      font-size: 18px;
    }
  `}
`

const StyledButtonCointainer = styled(Row)`
  & > div:first-of-type {
    margin-bottom: 10px;
  }
  ${media.md`
  & > div:first-of-type {
    margin-bottom: 0px;
  }
  `}
`

const StyledListContainer = styled(Row)`
  & > div > a {
    margin-bottom: 20px;
  }
  ${media.md`
    & > div > a{
      margin-bottom: 0px;
  }
  `}
`

const StyledPositionContainer = styled(Container)`
  margin-top: 55px;
  ${media.md`
    margin-top :230px;
  `}
`
export default JobOpeningPage

export const query = graphql`
  query (
    $page: ContentfulPageFilterListInput = {
      elemMatch: { title: { eq: "Careers" } }
    }
    $title: String!
  ) {
    contentfulJobOpening(title: { eq: $title }) {
      ...jobOpening
    }

    jobOpportunity: allContentfulJobList(filter: { page: $page }) {
      nodes {
        ...jobOpportunity
        background {
          localFile {
            childImageSharp {
              tabletImage: fixed(toFormat: WEBP, width: 400, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              desktopImage: fixed(toFormat: WEBP, width: 600, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }

    socialMedias: allContentfulSocialMediaGroup(
      filter: { page: $page }
      limit: 1
    ) {
      nodes {
        ...socialMedia
      }
    }
  }
`
